"use strict";

angular.module("informaApp")
    .service("GanttChartDataFilter", ["GanttChartMapper", function (GanttChartMapper) {
        function filterIfItemWithoutNoData(item, filterArray) {
            var wasChange = false;

            for (var i = 0; i < 4; i++){
                var phase = item.source.phases[i];

                if (!filterArray[i] && phase.value != null){
                    wasChange = true;

                    var duration = phase.value;

                    phase.value = null;
                    phase.offset = null;
                    phase.isFiltered = true;

                    changeOffsetOfPhases(item.source.phases, i, duration);

                    item.source.total -= duration;
                }
            }

            return wasChange;
        }

        function getNoDataDuration(phases, total) {
            var phasesWithData = phases.filter(function(bar) {
                return bar.value != null && bar.offset != null;
            });

            var sum = _.sum(phasesWithData.map(function (x) {
                return x.value;
            }));

            return total - sum;
        }

        function getStartingPhase(phases, total) {
            for (var i = 0; i < phases.length; i++){
                if (phases[i].value != null && phases[i].offset === 0){
                    return i;
                }
            }

            return 0;
        }

        function getLastPhase(phases) {
            var firstOffset = null;

            for (var i = 0; i < phases.length; i++){
                if (phases[i].value != null){
                    firstOffset = phases[i].offset;
                    break;
                }
            }

            if (firstOffset > 0){
                for (var i = phases.length - 1; i >= 0; i--){
                    if (phases[i].value != null){
                        return i;
                    }
                }
            }

            return phases.length - 1;
        }

        function filterNoData(item) {
            var data = hasInvalidData(item);

            if (!data.result){
                return false;
            }

            var startingPhase = data.startingPhase;
            var lastPhase = data.lastPhase;
            var durationNoData = data.durationNoData;

            item.source.total -= durationNoData;

            for (var i = lastPhase; i >= startingPhase; i--){
                var phase = item.source.phases[i];

                if (phase.value == null && !phase.isFiltered){
                    changeOffsetOfPhases(item.source.phases, i, durationNoData);
                    break;
                }
            }

            return true;
        }

        function hasInvalidData(item) {
            var startingPhase = getStartingPhase(item.source.phases, item.source.total);

            var lastPhase = item.failedPhase == null ? getLastPhase(item.source.phases) : item.failedPhase - 2;
            var durationNoData = getNoDataDuration(item.source.phases, item.source.total);

            return {
                result: durationNoData !== 0,
                startingPhase: startingPhase,
                lastPhase: lastPhase,
                durationNoData: durationNoData
            };
        }

        function changeOffsetOfPhases(phases, startIndex, value) {
            for (var i = startIndex + 1; i < 4; i++){
                if (phases[i].offset != null){
                    phases[i].offset -= value;
                }
            }
        }

        function getIndexByStatus(status) {
            switch (status){
                case true: return 2;
                case false: return 0;
                default: return 1;
            }
        }

        function filterByStatus(status, filter) {
            var index = getIndexByStatus(status);

            return filter[index];
        }

        function filterPhases(data, filter) {
            var filterArray = [filter.phase1, filter.phase2, filter.phase3, filter.ndabla, filter.noData];

            data = data.filter(function (item, i) {
                while (true){
                    if (filterIfItemWithoutNoData(item, filterArray)){
                        continue;
                    }

                    if (!filter.noData && filterNoData(item)){
                        continue;
                    }

                    break;
                }

                return item;
            });

            var filteredData = data.filter(function(x) {
                return x.source.total > 0;
            });

            return GanttChartMapper.sortDataByDuration(filteredData);
        }

        return {
            filterByStatus: function(status, filter) {
                const { failed, inProgress, succeeded } = filter;

                return filterByStatus(status, [failed, inProgress, succeeded]);
            },
            filterData: function (data, filter) {
                var statusFilter = [filter.failed, filter.inProgress, filter.succeeded];

                data = data.filter(function (item) {
                    return filterByStatus(item.status, statusFilter);
                });

                return filterPhases(data, filter);
            },
            hasInvalidData: function (item) {
                return hasInvalidData(item).result;
            }
        };
    }]);